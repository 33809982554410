import {FC, useState} from 'react'
import {Field} from 'formik'
import {useQuery, useQueryClient} from 'react-query'
import {Row} from 'react-bootstrap'
import {Client, DefaultExpeditionDate, ShippingAddress} from '../../../../models/models'
import {customerAddress, requestExpeditionDate} from '../../../../requests/requests'
import {useIntl} from 'react-intl'

const StepOrder3: FC = () => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const [minDeliveryDate, setMinDeliveryDate] = useState<Date>(new Date())
  const [selectedDate, setSelectedDate] = useState(
    localStorage.getItem('selectedExpeditionDate')
      ? localStorage.getItem('selectedExpeditionDate')
      : new Date(minDeliveryDate).toISOString().slice(0, 10)
  )
  const selectedAddress: ShippingAddress = queryClient.getQueryData(
    'selectedAddress'
  ) as ShippingAddress

  useQuery<Client[]>(
    ['selectedUser'],
    () => {
      return customerAddress(localStorage.getItem('selectedClientID') as string).then(
        ({data: x}) => {
          return x
        }
      )
    },
    {staleTime: 60 * 60 * 24 * 365, enabled: !!localStorage.getItem('selectedClientID')}
  )

  const {refetch: refetchMinDate, data: minExpeditionDate} = useQuery<DefaultExpeditionDate>(
    ['deliveryDate'],
    async () => {
      const {data: x} = await requestExpeditionDate()
      setSelectedDate(x.expeditionDate)
      setMinDeliveryDate(new Date(x.expeditionDate))
      return x
    },
    {staleTime: 60 * 60 * 24 * 365}
  )
  const selectedDateQuery: string = queryClient.getQueryData('selectedDate') as string

  if (selectedAddress && !selectedDateQuery) {
    refetchMinDate()
    queryClient.setQueryData(['selectedDate'], minExpeditionDate?.expeditionDate)
  }

  const handleDateChange = (e: any) => {
    const newValue = e.target.value
    const newDate = new Date(newValue)
    //if (newDate.getDay() === 6 || newDate.getDay() === 0) return
    if (newDate >= minDeliveryDate) {
      queryClient.setQueryData(['selectedDate'], newValue)
      setSelectedDate(newValue)
      localStorage.setItem('selectedExpeditionDate', newValue)
    }
  }
  const minDate = () => {
    return minDeliveryDate.toISOString().slice(0, 10)
  }
  const maxDate = () => {
    const minDate = new Date(minDeliveryDate.toISOString().slice(0, 10))
    minDate.setHours(24 * 30)
    return minDate.toISOString().slice(0, 10)
  }
  return (
    <section className='card card-flush p-8 flex-row-fluid overflow-hidden'>
      {/* CALENDAR */}
      <Row>
        <h2 className='text-dark mb-8'>
          {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEP3_TITLE_DATE'})}
        </h2>
      </Row>
      <Row className='mb-12'>
        <div className='w-100 w-lg-25'>
          <label className='mb-2' style={{color: '#99A1B7'}} htmlFor=''>
            {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEP3_SUBTITLE_DATE'})}
          </label>
          <Field
            className='form-control'
            type='date'
            name='selectedDate'
            id='deliveryDate'
            onChange={(e: any) => handleDateChange(e)}
            value={selectedDate}
            min={minDate()}
            max={maxDate()}
          />
        </div>
      </Row>
      <Row>
        <label htmlFor='deliveryDate' className='text-dark form-label'>
          <p className='mb-0'>
            {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEP2_SUBTITLE_DATE'})}
          </p>
          <p className='mb-0'>
            {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEP2_SUBTITLE_DATE_PART2'})}
          </p>
        </label>
        <p className='mb-0'>
          {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEP2_SUBTITLE_DATE_PART3'})}
        </p>
        <p className='mb-0'>
          {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEP2_SUBTITLE_DATE_PART4'})}
        </p>
      </Row>
    </section>
  )
}

export {StepOrder3}
