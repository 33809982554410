import {FC, useState} from 'react'
import {Button, Col, Row, Spinner} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {OrderGroupedByShippingAddress} from '../../models/myOrders'
import {requestMyLastOrdersGroupedByAddress} from '../../requests/requests'
import {useAuth} from '../../modules/auth'
import {configStaleTime} from '../../constants'
import {KTSVG} from '../../../_metronic/helpers'
import {getProductFormat} from '../../hooks/getProductFormat'
import {CSVLink} from 'react-csv'
import {FilterByExpeditionDate} from '../../components/FilterByExpeditionDate'
import {formatDateType} from '../../helpers/formatDate'

export const OrdersGroupedByAddress: FC = () => {
  const intl = useIntl()
  const {auth} = useAuth()
  const {isFetching, data, refetch} = useQuery<OrderGroupedByShippingAddress[]>(
    ['ordersGroupedByAddress'],
    async () => {
      const {data} = await requestMyLastOrdersGroupedByAddress(
        (filteredDate as Date).toISOString().slice(0, 10)
      )
      return data
    },
    {staleTime: configStaleTime, enabled: !!auth}
  )
  const [filteredDate, setFilteredDate] = useState<Date>(new Date())
  let dataForCsv = [] as any[]
  if (data) {
    data.forEach((el) => {
      if (el.orderLines) {
        el.orderLines.forEach((line) => {
          dataForCsv.push({
            ShippingAddressDescription: el.shippingAddressDescription,
            ShippingAddress: `${el.shippingAddress} (${el.shippingAddressCity}, ${el.shippingAddressPostalCode})`,
            Details: `${line.quantity} ${getProductFormat(
              line.packageUnit,
              line.stockRatio,
              intl.formatMessage({id: 'GENERAL.OF'}),
              ''
            )}`,
            Description: `${line.description}`,
            DeliveryNotes: `${
              line.deliveryNotes && line.deliveryNotes.length > 0 && line.deliveryNotes.join(' ')
            }`,
            OrderStatus: `${line.sageOrderStatus}`,
            KgServed: `${line.kgServed <= 0 ? '-' : line.kgServed.toLocaleString('es-ES')}`,
          })
        })
      }
    })
  }
  const delayedRefetch = () => {
    setTimeout(() => {
      refetch()
    }, 100)
  }
  return (
    <>
      {!data && isFetching && (
        <>
          {intl.formatMessage({id: 'FETCH.LOADING'})} <Spinner animation='border'></Spinner>
        </>
      )}
      {data && (
        <>
          <div className='d-flex justify-content-between flex-column flex-lg-row mb-5'>
            <div className='w-100'>
              <h1>{intl.formatMessage({id: 'ORDERS.GROUPED_BY_ADDRESS.TITLE'})}</h1>
              <span className='text-gray-400 pt-1 fw-semibold fs-6'>
                {intl.formatMessage({id: 'ORDERS.GROUPED_BY_ADDRESS.DESCRIPTION'})}
              </span>
            </div>
            <div className='text-end w-100'>
              {data.length > 0 && (
                <CSVLink
                  data={dataForCsv}
                  target='_blank'
                  separator={';'}
                  filename={'export-orders-grouped-by-address.csv'}
                >
                  <Button type='button' className={`my-2 ${data.length > 0 ? '' : 'disabled'}`}>
                    {intl.formatMessage({id: 'GENERAL.EXPORT_TO_EXCEL'})}
                  </Button>
                </CSVLink>
              )}
              {data.length <= 0 && (
                <Button type='button' className={`my-2 ${data.length > 0 ? '' : 'disabled'}`}>
                  {intl.formatMessage({id: 'GENERAL.EXPORT_TO_EXCEL'})}
                </Button>
              )}
            </div>
          </div>

          {/* ACCORDION */}
          <section className='card mb-6'>
            <div className='card-body'>
              <Row>
                <Col>
                  {data.length > 0 && (
                    <>
                      <span className='badge badge-dark mt-3 mb-3 mb-lg-0'>
                        {intl.formatMessage({id: 'GENERAL_SHOWING_ORDERS_OF'})}{' '}
                        {filteredDate && <>&nbsp;{formatDateType(filteredDate)}</>}
                      </span>
                    </>
                  )}
                </Col>
                <Col>
                  <FilterByExpeditionDate
                    onSubmit={() => {
                      delayedRefetch()
                      //   const dateInput = document.querySelector(
                      //     'input[name="selectedDate"]'
                      //   ) as HTMLInputElement
                      //   if (dateInput) {
                      //     saveFilter(
                      //       'filteredExpeditionDate',
                      //       dateInput.valueAsDate?.getTime() as number
                      //     )
                      //   }
                    }}
                    filteredDate={filteredDate}
                    setFilteredDate={setFilteredDate}
                  />
                </Col>
              </Row>
              <Row className='mt-5'>
                {data.length <= 0 && (
                  <Col>
                    <div className='alert alert-primary m-0' role='alert'>
                      {intl.formatMessage({id: 'FETCH.NO_RESULTS'})}
                    </div>
                  </Col>
                )}
              </Row>
              <div className='accordion accordion-icon-toggle' id='orders-by-address-accordion'>
                {data &&
                  data.map((order: OrderGroupedByShippingAddress, index) => {
                    return (
                      <div key={`wrapper-summary-${index}`}>
                        <div
                          className='accordion-header py-3 d-flex'
                          data-bs-toggle='collapse'
                          data-bs-target={`#orders-by-address-accordion-${index}`}
                        >
                          <span className='accordion-icon'>
                            <KTSVG
                              className='svg-icon svg-icon-4'
                              path='media/icons/duotune/arrows/arr064.svg'
                            />
                          </span>
                          <Row className='w-100 justify-content-between align-items-center'>
                            <Col>
                              <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>
                                <span>
                                  {order.shippingAddressDescription},&nbsp;
                                  {order.shippingAddress} {order.shippingAddressPostalCode} ·{' '}
                                  {order.shippingAddressCity}
                                </span>
                              </h3>
                            </Col>
                            <Col xs='12' lg='auto'>
                              <div className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>
                                {/* {order.orderLines.reduce(
                                  (acc, curr) => acc + curr.stockRatio * curr.quantity,
                                  0
                                )}{' '} */}
                                {/* KG */}
                                {order.totalKgServed} KG
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div
                          id={`orders-by-address-accordion-${index}`}
                          className='fs-6 collapse ps-lg-10 show'
                          data-bs-parent={`#orders-by-address-accordion-${index}`}
                        >
                          <div
                            className='accordion accordion-icon-toggle'
                            id='orders-by-address-accordion-submenu'
                          >
                            {/* LIST*/}
                            <ul className='p-0'>
                              {order &&
                                order.orderLines &&
                                order.orderLines.map((line, index) => {
                                  return (
                                    <li
                                      key={`summary-orders-${line.guidId}-${index}`}
                                      className='card d-flex flex-column flex-lg-row py-2 px-4 mb-2'
                                    >
                                      <Row className='justify-content-between align-items-end align-items-lg-center w-100'>
                                        <Col xs='12' lg='2' className='mb-3 mb-lg-0'>
                                          <span className='w-100 w-lg-auto'>
                                            {line.clientBusinessName}
                                          </span>
                                        </Col>
                                        <Col xs='12' lg='2' className='mb-3 mb-lg-0'>
                                          <span className='w-100 w-lg-auto'>
                                            {line.description}
                                          </span>
                                        </Col>
                                        <Col xs='12' lg='2' className='mb-3 mb-lg-0'>
                                          <span className='w-100 w-lg-auto'>
                                            {line.quantity}&nbsp;
                                            {getProductFormat(
                                              line.packageUnit,
                                              line.stockRatio,
                                              intl.formatMessage({id: 'GENERAL.OF'}),
                                              ''
                                            )}{' '}
                                          </span>
                                        </Col>
                                        <Col xs='12' lg='1' className='text-end mb-3 mb-lg-0'>
                                          <span>
                                            {line.kgServed <= 0 ? <>-</> : <>{line.kgServed} KG</>}
                                          </span>
                                        </Col>
                                        <Col xs='12' lg='3' className='mb-3 mb-lg-0'>
                                          {line.deliveryNotes &&
                                            line.deliveryNotes.map((x) => (
                                              <span className='badge badge-light-primary me-3'>
                                                {x}
                                              </span>
                                            ))}
                                        </Col>
                                        <Col xs='12' lg='1' className='text-lg-center mb-3 mb-lg-0'>
                                          <span
                                            className={`badge ${
                                              order.orderLines &&
                                              order.orderLines.length > 0 &&
                                              order.orderLines.find(
                                                (x) => x.invoices && x.invoices.length > 0
                                              )
                                                ? 'badge-light-success'
                                                : 'badge-light-primary'
                                            }`}
                                          >
                                            {line.sageOrderStatus}
                                          </span>
                                        </Col>
                                      </Row>
                                    </li>
                                  )
                                })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          </section>
        </>
      )}
    </>
  )
}
