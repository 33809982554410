/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
// import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {auth} = useAuth()

  return (
    <>
      {/* DASHBOARD */}
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            {intl.formatMessage({id: 'MENU.SECTIONS'})}
          </span>
        </div>
      </div>

      {/* ORDERS */}
      <SidebarMenuItemWithSub to='#' icon='basket' title={intl.formatMessage({id: 'MENU.ORDERS'})}>
        <SidebarMenuItem
          to={`${auth?.isAdmin ? '/new-order' : '/orders'}`}
          title={intl.formatMessage({id: 'MENU.CREATE_ORDER'})}
          hasBullet={true}
        />
        {!auth?.isAdmin && (
          <>
            <SidebarMenuItem
              to='/my-orders'
              title={intl.formatMessage({id: 'MENU.LIST_MY_ORDERS'})}
              hasBullet={true}
            />
            <SidebarMenuItem
              to='/orders-by-address'
              title={intl.formatMessage({id: 'MENU.LIST_MY_ORDERS_GROUPED_BY_ADDRESS'})}
              hasBullet={true}
            />
          </>
        )}
      </SidebarMenuItemWithSub>

      {/* NEWS */}
      <SidebarMenuItem
        to='/news'
        icon='message-text'
        title={intl.formatMessage({id: 'MENU.NEWS'})}
        fontIcon='bi-app-indicator'
      />

      {/* ADMIN EDITS*/}
      {auth && auth.isAdmin && (
        <SidebarMenuItemWithSub to='#' icon='gear' title='Administrar'>
          <SidebarMenuItem
            to='/products'
            title={intl.formatMessage({id: 'MENU.MANAGE_PRODUCTS'})}
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/categories'
            title={intl.formatMessage({id: 'MENU.MANAGE_CATEGORIES'})}
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/manage-news'
            title={intl.formatMessage({id: 'MENU.MANAGE_NEWS'})}
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
      )}

      {/* ADMIN ORDERS */}
      {auth && auth.isAdmin && (
        <SidebarMenuItemWithSub to='#' icon='menu' title='Gestionar Pedidos'>
          <SidebarMenuItem
            to='/validate-orders'
            title={intl.formatMessage({id: 'MENU.LIST_VALIDATE_ORDERS'})}
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/all-orders-with-filters'
            title={intl.formatMessage({id: 'MENU.LIST_ALL_ORDERS_WITH_FILTERS'})}
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
      )}
    </>
  )
}

export {SidebarMenuMain}
