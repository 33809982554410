import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toastify = () => {
  return (
    <div>
        <ToastContainer />
    </div>
  );
};

const enum ToastifyType {
  DEFAULT,
  INFO,
  SUCCESS,
  WARNING,
  ERROR
}

const ToastifyCaller = (message, type = ToastifyType.DEFAULT, position?:string, autoClose?:number, hideProgressBar?:boolean, closeOnClick?:boolean, pauseOnHover?:boolean, draggable?:boolean, theme?:string, progress?:any) => {
  const extraArgs = {
    position: position ? position : "top-right",
    autoClose: autoClose ? autoClose : 5000,
    hideProgressBar: hideProgressBar ? hideProgressBar : false,
    closeOnClick: closeOnClick ? closeOnClick : true,
    pauseOnHover: pauseOnHover ? pauseOnHover : false,
    draggable: draggable ? draggable : true,
    progress: progress ? progress : undefined,
    theme: theme ? theme : "light",
  };
  switch (type) {
    case ToastifyType.DEFAULT: {
      return toast(message, extraArgs as {});
    }
    case ToastifyType.INFO: {
      return toast.info(message, extraArgs as {});
    }
    case ToastifyType.SUCCESS: {
      return toast.success(message, extraArgs as {});
    }
    case ToastifyType.WARNING: {
      return toast.warning(message, extraArgs as {});
    }
    case ToastifyType.ERROR: {
      return toast.error(message, extraArgs as {});
    }
  }
};

export {Toastify, ToastifyCaller, ToastifyType};