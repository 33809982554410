import {Modal, Button, Form, Row, FormGroup, FormLabel, Col} from 'react-bootstrap'
import {Field, Formik} from 'formik'
import {useIntl} from 'react-intl'
import {MyOrder} from '../../models/myOrders'
import {requestModifyOrder} from '../../requests/requests'
import {OrderEdit} from '../../models/orderEdit'
import {ToastifyCaller, ToastifyType} from '../Toastify'
import {inputDateFormat} from '../../helpers/formatDate'
import {useMinExpeditionDate} from '../../hooks/useMinExpeditionDate'
import {useState} from 'react'
import {useQueryValidateOrders} from '../../hooks/useQueryValidateOrders'

export const UpdateExpeditionDateModal = ({
  show,
  onHide,
  refetch,
}: {
  show: MyOrder | undefined
  onHide: any
  refetch: any
}) => {
  const intl = useIntl()
  const {refetchValidateOrders} = useQueryValidateOrders(new Date())
  const [refetchedMinDate, setRefetchedMinDate] = useState(false)
  const [minExpeditionDate, maxExpeditionDate, refetchMinDate] = useMinExpeditionDate()
  const minDate = minExpeditionDate as Date
  const maxDate = maxExpeditionDate as Date
  const refetchMin = refetchMinDate as any

  if (!refetchedMinDate) {
    refetchMin()
    setRefetchedMinDate(true)
  }

  return (
    <Modal
      show={show ? true : false}
      onHide={onHide}
      dialogClassName='modal-dialog modal-dialog-centered modal-lg'
    >
      <Modal.Header closeButton>
        <h2 className='text-black'>
          {intl.formatMessage({id: 'UPDATE_EXPEDITION_DATE_MODAL.TITLE'})}
        </h2>
      </Modal.Header>
      <Modal.Body className='modal-body py-lg-10 px-lg-20'>
        <Formik
          initialValues={{
            expeditionDate: show ? inputDateFormat(show!.expeditionDate) : '',
          }}
          onSubmit={async ({expeditionDate}) => {
            if (show && show?.expeditionDate && expeditionDate) {
              show.expeditionDate = expeditionDate
              const orderEditObj = {
                guidId: show.guidId,
                clientId: show.clientId,
                expeditionDate: show.expeditionDate,
                detail: show.detail,
                lines: show.lines,
              } as OrderEdit
              const response = await requestModifyOrder(orderEditObj)
              const allOK = response.status >= 200 && response.status
              if (allOK) {
                onHide()
                await refetch()
                await refetchValidateOrders()
                ToastifyCaller(
                  intl.formatMessage({id: 'UPDATE_EXPEDITION_DATE_MODAL.UPDATE_OK'}),
                  ToastifyType.SUCCESS
                )
              }
            }
          }}
        >
          {({handleSubmit, handleChange}) => (
            <Form onSubmit={handleSubmit}>
              <Row className='mb-12'>
                <Col xs='12'>
                  <FormGroup>
                    <Field
                      type='date'
                      id='expeditionDate'
                      name='expeditionDate'
                      className='form-control'
                      min={inputDateFormat(minDate.toDateString())}
                      max={inputDateFormat(maxDate.toDateString())}
                      onChange={(e) => {
                        const canChangeValue =
                          new Date(e.target.value).getDay() !== 6 &&
                          new Date(e.target.value).getDay() !== 0
                        if (canChangeValue) {
                          handleChange(e)
                        }
                      }}
                    />
                    <FormLabel htmlFor='expeditionDate' />
                  </FormGroup>
                </Col>
              </Row>

              <div className='d-flex justify-content-end'>
                <Button
                  type='reset'
                  className='btn btn-secondary btm-sm mt-8  me-4'
                  onClick={onHide}
                >
                  {intl.formatMessage({id: 'BUTTON.CANCEL'})}
                </Button>
                <Button variant='primary' type='submit' className='btn btn-primary btm-sm mt-8'>
                  {intl.formatMessage({id: 'BUTTON.SAVE'})}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}
