import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {useAuth} from '../../../../../app/modules/auth'

export function MenuInner() {
  const intl = useIntl()
  const {auth} = useAuth()

  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />
      {/* <MenuItem title={intl.formatMessage({id: 'MENU.COMMANDS'})} to='/orders' /> */}

      <MenuInnerWithSub
        title={intl.formatMessage({id: 'MENU.COMMANDS'})}
        to={`${auth?.isAdmin ? '/new-order' : '/orders'}`}
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuItem
          to={`${auth?.isAdmin ? '/new-order' : '/orders'}`}
          title={intl.formatMessage({id: 'MENU.CREATE_ORDER'})}
          hasBullet={true}
        />
        {!auth?.isAdmin && (
          <>
            <MenuItem
              to='/my-orders'
              title={intl.formatMessage({id: 'MENU.LIST_MY_ORDERS'})}
              hasBullet={true}
            />

            <MenuItem
              to='/orders-by-address'
              title={intl.formatMessage({id: 'MENU.LIST_MY_ORDERS_GROUPED_BY_ADDRESS'})}
              hasBullet={true}
            />
          </>
        )}
      </MenuInnerWithSub>
      <MenuItem title={intl.formatMessage({id: 'MENU.NEWS'})} to='/news' />

      {/* ADMIN EDITS*/}
      {auth && auth.isAdmin && (
        <MenuInnerWithSub
          to='#'
          title='Administrar'
          menuPlacement='bottom-start'
          menuTrigger='click'
        >
          <MenuItem
            to='/products'
            title={intl.formatMessage({id: 'MENU.MANAGE_PRODUCTS'})}
            hasBullet={true}
          />
          <MenuItem
            to='/categories'
            title={intl.formatMessage({id: 'MENU.MANAGE_CATEGORIES'})}
            hasBullet={true}
          />
          <MenuItem
            to='/manage-news'
            title={intl.formatMessage({id: 'MENU.MANAGE_NEWS'})}
            hasBullet={true}
          />
        </MenuInnerWithSub>
      )}

      {/* ADMIN ORDERS */}
      {auth && auth.isAdmin && (
        <MenuInnerWithSub
          to='#'
          title='Gestionar pedidos'
          menuPlacement='bottom-start'
          menuTrigger='click'
        >
          <MenuItem
            to='/validate-orders'
            title={intl.formatMessage({id: 'MENU.LIST_VALIDATE_ORDERS'})}
            hasBullet={true}
          />
          <MenuItem
            to='/all-orders-with-filters'
            title={intl.formatMessage({id: 'MENU.LIST_ALL_ORDERS_WITH_FILTERS'})}
            hasBullet={true}
          />
        </MenuInnerWithSub>
      )}
    </>
  )
}
